.App {
  text-align: center;
}

video {
  outline: none;
}

*:focus {
  background: none;
  border: 0px;
  outline: none;
}
